import React, { useState } from "react";
import styled from "styled-components";

// Styles
import { secondaryLight, primaryLight } from "../utils/colors";

const NavComponent = styled.div`
	position: fixed;

	top: 52px;
	left: 0;
	right: 0;

	width: 100%;
	z-index: 10;

	background-color: ${primaryLight};

	& ol {
		margin: 0;
		padding: 0 15px;

		& li {
			& a {
				&:hover {
					color: ${secondaryLight};
				}
			}

			& a.current {
				color: ${secondaryLight};
				&:hover {
					color: inherit;
				}
			}
		}
	}

	& ol.mobile-filter-button {
		display: block;
	}

	& ol.filter-list {
		display: ${(props) => props.isOpen};

		& ol {
			padding: 0;
		}
	}

	& ol {
		& li {
			line-height: 50px;
		}
	}
	position: fixed;
`;

export const MobileNavList = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<NavComponent isOpen={isOpen === true ? `block` : `none`}>
			<ol className="mobile-filter-button">
				<li>
					<button onClick={() => setIsOpen(!isOpen)}>
						{isOpen === true ? `Close` : `Account Menu`}
					</button>
				</li>
			</ol>
			<ol className="filter-list">{children}</ol>
		</NavComponent>
	);
};
