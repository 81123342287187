import React, { useContext } from "react";
import { Link } from "gatsby";
import { Query } from "react-apollo";
import gql from "graphql-tag";

// Context
import StoreContext from "../../components/context/store";

// Containers
import { MobileNavList } from "../containers/mobile-navlist";
import Logout from "../../pages/account/logout";

const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			tags
		}
	}
`;

export const MobileAccountNavigation = ({ position }) => {
	const { customerAccessToken } = useContext(StoreContext);

	return (
		<Query
			query={CUSTOMER_INFO}
			variables={{
				customerAccessToken: customerAccessToken.accessToken,
			}}
		>
			{({ loading, error, data }) => {
				const isTrade =
					data !== undefined &&
					data.customer !== undefined &&
					data.customer !== null
						? data.customer.tags.includes("trade") || data.customer.tags.includes("Trade")
						: false;

				return (
					<MobileNavList>
						<ol>
							{isTrade && (
								<>
									<li>
										<Link to={`/account/trade`} activeClassName="current">
											Trade Account{" "}
										</Link>
									</li>
									<li>
										<Link
											to={`/account/trade/products`}
											activeClassName="current"
										>
											Products
										</Link>
									</li>
									<li>
										<Link
											to={`/account/trade/materials-library`}
											activeClassName="current"
										>
											Materials Library
										</Link>
										<br />
									</li>
								</>
							)}

							<li>
								<Link to={`/account`} activeClassName="current">
									Your Account
								</Link>
							</li>
							<li>
								<Link to={`/account/addresses`} activeClassName="current">
									Addresses
								</Link>
							</li>
							<li>
								<Link to={`/account/orders`} activeClassName="current">
									Order History
								</Link>
							</li>
							<li>
								<Logout />
							</li>
						</ol>
					</MobileNavList>
				);
			}}
		</Query>
	);
};
