import React from "react";
import styled from "styled-components";

// Styles
import { secondaryDark, secondaryLight } from "../utils/colors";

const NavComponent = styled.div`
	position: relative;
	left: 20px;

	z-index: 200;
	width: 150px;

	& ol:empty {
		display: none;
	}

	& a {
		transition: 150ms color ease;

		&:hover {
			color: ${secondaryLight};
		}
	}

	& a.current {
		color: ${secondaryLight};
		&:hover {
			color: inherit;
		}
	}

	& ol {
		margin: 0;
		padding: 0;

		display: inline-block;
	}

	& li {
		& button,
		& a {
			display: block;

			color: ${secondaryDark};

			text-align: left;

			margin: 0;
			padding: 0;
		}
	}

	@media (max-width: 1024px) {
		display: ${(props) =>
			props.hideSubnavOnMobile ? props.hideSubnavOnMobile : `block`};

		position: relative;
		top: 0;
		left: 0;

		width: 100%;

		order: 2;

		padding: 61px 20px 42px 20px;
		margin: 0;

		& li {
			& button,
			& a {
				color: ${secondaryDark};

				font-size: 18px;
				line-height: 20px;

				margin: 0 0 20px 0;
			}

			& a.page-title {
			}
		}

		& .inactive-link {
			display: none;
		}
	}

	@media (max-width: 768px) {
		padding: 30px 15px;

		& li {
			& button,
			& a {
				color: ${secondaryDark};

				font-size: 12px;
				line-height: 14px;

				margin: 0 0 10px 0;
			}
		}
	}
`;

export const NavList = ({
	children,
	position,
	color,
	top,
	hideSubnavOnMobile,
}) => (
	<NavComponent
		color={color}
		hideSubnavOnMobile={hideSubnavOnMobile === true ? `none` : `block`}
	>
		{children}
	</NavComponent>
);
