import React from "react";
import styled from "styled-components";

// Styles
import { secondaryDark, secondaryLight, tertiary } from "../utils/colors";

export const Page = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	margin: 150px 0 0 0;
`;

export const AccountContainer = styled.div`
	width: calc(100% - 190px);

	@media (max-width: 1024px) {
		width: 100%;
		margin: 0 0 40px 0;
	}
`;

export const ContentContainer = styled.div`
	max-width: 1010px;
	margin: 0 auto;
	padding: 0 20px;

	& .account-text,
	& .product-text {
		width: 100%;
		max-width: 570px;
	}

	@media (max-width: 768px) {
		padding: 0 15px;
	}

	& .text-column {
		max-width: 570px;
		margin: 0 0 70px 0;
	}

	& h1,
	& h2 {
		font-size: 16px;
		line-height: 20px;
		margin: 0 0 20px 0;
	}

	& .button-container {
		margin: 20px 0 0 0;
		& .delete-button {
			margin: 0 0 0 20px;
		}
	}

	& a,
	& button {
		&:hover {
			color: ${secondaryLight};
		}
	}

	& .field {
		& label {
			line-height: 1;

			cursor: pointer;
		}
	}

	& .field {
		& label {
			line-height: 1;

			cursor: pointer;
		}

		& .checkbox {
			line-height: 1;

			& input {
				line-height: 1;
			}
		}

		.checkbox input[type="checkbox"] {
			opacity: 0;
			height: 12px;
			width: 12px;
		}

		.checkbox label {
			position: relative;
			display: inline-block;

			/*16px width of fake checkbox + 6px distance between fake checkbox and text*/
			padding-left: 22px;
			margin-left: -13px;
		}

		.checkbox label::before,
		.checkbox label::after {
			position: absolute;
			content: "";

			/*Needed for the line-height to take effect*/
			display: inline-block;
		}

		/*Outer box of the fake checkbox*/
		.checkbox label::before {
			height: 14px;
			width: 14px;

			border: 1px solid ${secondaryDark};
			left: 0;
			top: 0;
		}

		/*Checkmark of the fake checkbox*/
		.checkbox label::after {
			height: 6px;
			width: 6px;
			background-color: ${secondaryDark};
			left: 3px;
			top: 3px;
		}

		/*Hide the checkmark by default*/
		.checkbox input[type="checkbox"] + label::after {
			content: none;
		}

		/*Unhide on the checked state*/
		.checkbox input[type="checkbox"]:checked + label::after {
			content: "";
		}
	}

	& a {
		color: ${secondaryDark};
	}

	& .section {
		margin: 0 0 70px 0;
	}

	& button {
		color: ${secondaryDark};

		&:first-of-type {
			margin-right: 20px;
		}
	}

	@media (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
		margin: 0 0 40px 0;
	}
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 30px;
`;

export const TwoColumnGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
`;

export const AddressFields = styled.form`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	& .form-header,
	& .form-footer,
	& .form-submit-container {
		width: 100%;
	}

	& .form-footer {
		margin: 10px 0 0 0;

		& label {
			line-height: 1;

			cursor: pointer;
		}

		& .checkbox {
			line-height: 1;

			& input {
				line-height: 1;
			}
		}

		.checkbox input[type="checkbox"] {
			opacity: 0;
			height: 12px;
			width: 12px;
		}

		.checkbox label {
			position: relative;
			display: inline-block;

			/*16px width of fake checkbox + 6px distance between fake checkbox and text*/
			padding-left: 22px;
			margin-left: -13px;
		}

		.checkbox label::before,
		.checkbox label::after {
			position: absolute;
			content: "";

			/*Needed for the line-height to take effect*/
			display: inline-block;
		}

		/*Outer box of the fake checkbox*/
		.checkbox label::before {
			height: 14px;
			width: 14px;

			border: 1px solid ${secondaryDark};
			left: 0;
			top: 0;
		}

		/*Checkmark of the fake checkbox*/
		.checkbox label::after {
			height: 8px;
			width: 8px;
			background-color: ${secondaryDark};
			left: 4px;
			top: 4px;
			border-radius: 100%;
		}

		/*Hide the checkmark by default*/
		.checkbox input[type="checkbox"] + label::after {
			content: none;
		}

		/*Unhide on the checked state*/
		.checkbox input[type="checkbox"]:checked + label::after {
			content: "";
		}
	}

	& .form-submit-container {
		display: flex;
		flex-direction: row;
		align-items: center;

		margin: 2px 0 22px 0;
	}
`;

export const CountrySelector = styled.div`
	width: calc(50% - 20px);

	margin: 0 0 15px 0;

	& > div {
		width: 100%;

		& .dropdown__control {
			border: 0;
			border-bottom: 1px solid ${secondaryDark};
			border-radius: 0;

			min-height: 17px;

			cursor: pointer;
		}

		& .dropdown__control--is-focused {
			box-shadow: none;
		}

		& .dropdown__value-container {
			padding: 0;
			color: ${secondaryDark};
		}

		& .dropdown__single-value,
		& .dropdown__placeholder {
			margin: 0;
			padding: 0 0 2px 0;
			color: ${secondaryDark};

			position: relative;
			top: 0;
			transform: none;
		}

		& .dropdown__placeholder {
			color: ${secondaryDark};
		}

		& .dropdown__indicator-separator {
			display: none;
		}

		& .dropdown__indicator {
			padding: 0;

			& > svg {
				width: 10px;
				height: 10px;
			}
		}

		& .dropdown__menu {
			border: 1px solid ${secondaryDark};
			border-radius: 0;
			box-shadow: none;
		}

		& .dropdown__option--is-focused {
			background-color: ${tertiary};
		}

		& .css-b8ldur-Input {
			margin: 0;
			padding: 0;
		}
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const AddressInputField = styled.input`
	border: 0;
	border-bottom: 1px solid ${secondaryDark};

	width: ${(props) => (props.desktopWidth ? props.desktopWidth : `100%`)};

	padding: 0 0 2px 0;
	margin: 0 0 15px 0;

	::-webkit-input-placeholder {
		color: ${secondaryDark};
	}
	::-moz-placeholder {
		color: ${secondaryDark};
	}
	:-ms-input-placeholder {
		color: ${secondaryDark};
	}
	:-moz-placeholder {
		color: ${secondaryDark};
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;
