import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Query } from "react-apollo";
import gql from "graphql-tag";

// Context
import StoreContext from "../../components/context/store";

// Containers
import { NavList } from "../containers/navlist";
import Logout from "../../pages/account/logout";

const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			tags
		}
	}
`;

export const AccountNavigation = () => {
	const { customerAccessToken } = useContext(StoreContext);

	const brochureData = useStaticQuery(graphql`
		{
			prismicAccounts {
				data {
					brochure_file {
						url
					}
				}
			}
		}
	`);

	return (
		<Query
			query={CUSTOMER_INFO}
			variables={{
				customerAccessToken: customerAccessToken.accessToken,
			}}
		>
			{({ loading, error, data }) => {
				const isTrade =
					data !== undefined &&
					data.customer !== undefined &&
					data.customer !== null
						? data.customer.tags.includes("trade") || data.customer.tags.includes("Trade")
						: false;

				return (
					<NavList top={`2px`}>
						<ol>
							{isTrade && (
								<>
									<li>
										<Link to={`/account/trade`} activeClassName="current">
											Trade Account{" "}
										</Link>
									</li>
									<li>
										<Link
											to={`/account/trade/products`}
											activeClassName="current"
										>
											Products
										</Link>
									</li>
									<li>
										<Link
											to={`/account/trade/materials-library`}
											activeClassName="current"
										>
											Materials Library
										</Link>
										<br />
									</li>
								</>
							)}

							<li>
								<Link to={`/account/buying-guides`} activeClassName="current">
									Buying Guides
								</Link>
							</li>
							<li>
								<Link to={`/account/care-guides`} activeClassName="current">
									Care Guides
								</Link>
							</li>
							<li>
								<a
									href={brochureData.prismicAccounts.data.brochure_file.url}
									target="_blank"
									rel="noreferrer"
									download
								>
									Brochure
								</a>
							</li>

							<br />

							<li>
								<Link to={`/account`} activeClassName="current">
									Your Account
								</Link>
							</li>
							<li>
								<Link to={`/account/addresses`} activeClassName="current">
									Addresses
								</Link>
							</li>

							<li>
								<Link to={`/account/orders`} activeClassName="current">
									Order History
								</Link>
							</li>

							<br />

							<li>
								<Logout />
							</li>
						</ol>
					</NavList>
				);
			}}
		</Query>
	);
};
